@use "../scss/variables.scss" as vars;

.textareaContainer {
  height: 100px;
  font-size: medium;
}
.white {
  border: 1px solid white;
}
.dark {
  border: 1px solid black;
}

.button {
  border-radius: 20px;
  background-color: black;
  height: 50px;

  .internal {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    border-radius: 20px;
    background-color: white;
    width: 98%;
    height: 100%;
    overflow: hidden;
    img {
      max-height: 85%;
      width: 45%;
    }
  }
}

.buttonDark {
  border-radius: 20px;
  background-color: #ffc000;
  height: 50px;

  .internal {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid #ffc000;
    border-radius: 20px;
    background-color: white;
    width: 98%;
    height: 100%;
    overflow: hidden;
    img {
      max-height: 85%;
      width: 45%;
    }
  }
}
