@use "./variables" as vars;

@mixin button-review-rounded {
  border-radius: 15px !important;
}

@mixin button-review-big {
  border-radius: 0.8rem !important;
  padding: 1.3rem 1.2rem;

  & .btn-icon {
    font-size: 120%;
    padding-left: 1.2rem;

    & svg {
      font-size: 2rem;
    }
  }
}

@mixin button-review-full {
  min-width: 100%;
}

@mixin button-review($background, $foregreound) {
  position: relative;
  // text-transform: uppercase;
  padding: 1rem 2.5rem;
  width: fit-content;
  min-width: 5rem;
  min-height: 4rem;
  justify-content: center;
  align-items: center;
  border: 0;
  font-family: vars.$font-heading;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 110%;
  text-decoration: none;
  display: flex;
  text-align: center;
  // text-shadow: 2px 2px 4px black;

  color: $foregreound !important;
  background-color: $background !important;

  // &:hover {
  //   cursor: pointer;
  //   color: vars.$color-light !important;
  //   background-color: lighten($background, 20%) !important;
  // }

  &:disabled {
    color: vars.$color-light !important;
    background-color: lighten($background, 40%) !important;
  }

  &.rounded {
    @include button-review-rounded;
  }

  &.big {
    @include button-review-big;
  }

  &.full {
    @include button-review-full;
  }

  & .btn-icon {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding-left: 1rem;
  }
}

@mixin button-review-light($background, $foregreound) {
  position: relative;
  text-transform: uppercase;
  padding: 0.8rem 1rem;
  min-width: 9rem;
  min-height: 2.4rem;
  justify-content: center;
  align-items: center;
  border: 0;
  font-family: vars.$font-heading;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 110%;
  text-decoration: none;
  display: inline-block;
  text-align: center;

  color: $foregreound !important;
  background-color: $background !important;

  &:hover {
    cursor: pointer;
    color: vars.$color-light !important;
    background-color: lighten($foregreound, 20%) !important;
  }

  &:disabled {
    color: vars.$color-light !important;
    background-color: lighten($background, 40%) !important;
  }

  &.rounded {
    @include button-review-rounded;
  }

  &.big {
    @include button-review-big;
  }

  &.full {
    @include button-review-full;
  }

  & .btn-icon {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding-left: 1rem;
  }
}

.btn-light {
  @include button-review(vars.$color-light, vars.$color-dark);

  &:hover {
    color: vars.$color-dark !important;
    background-color: lighten(vars.$color-dark, 70%) !important;
  }
}

.btn-home {
  position: relative;
  padding: 0.9rem 2rem;
  min-width: 5rem;
  min-height: 5vh;
  width: fit-content;
  justify-content: center;
  align-items: center;
  border: 0;
  font-family: vars.$font-helvetica;
  font-weight: 700;
  font-size: 2.2rem;
  line-height: 110%;
  text-decoration: none;
  display: flex;
  text-align: center;
  border-radius: 30px;

  color: vars.$color-light !important;
  background-color: vars.$color-dark_grey_50 !important;

  &:hover {
    cursor: pointer;
    color: vars.$color-black !important;
    background-color: vars.$color-light !important;
  }
}

.btn-dark {
  @include button-review(vars.$color-dark, vars.$color-light);
}

.btn-dark-blue-materials {
  @include button-review(vars.$color-dark-blue, vars.$color-light);
  text-transform: none !important;
  font-size: 1.2rem;
}

.btn-dark-blue {
  @include button-review(vars.$color-dark-blue, vars.$color-light);
}

.btn-blue {
  @include button-review(vars.$color-review-blue, vars.$color-light);
}

.btn-red {
  @include button-review(vars.$color-review-red, vars.$color-light);
}

.btn-yellow {
  @include button-review(vars.$color-review-yellow, vars.$color-light);
}

.btn-green {
  @include button-review(vars.$color-review-green, vars.$color-light);
}

.btn-success {
  @include button-review(vars.$color-success, vars.$color-light);
}
.btn-danger {
  @include button-review(vars.$color-danger, vars.$color-light);
}
.btn-warning {
  @include button-review(vars.$color-warning, vars.$color-light);
}
.btn-white {
  @include button-review-light(vars.$color-white, vars.$color-dark_grey_90);
}
.btn-light_grey_5 {
  @include button-review-light(
    vars.$color-light_grey_5,
    vars.$color-dark_grey_90
  );
}
.btn-light_grey_10 {
  @include button-review-light(
    vars.$color-light_grey_10,
    vars.$color-dark_grey_90
  );
}
.btn-light_grey_15 {
  @include button-review-light(
    vars.$color-light_grey_15,
    vars.$color-dark_grey_90
  );
}
.btn-light_grey_20 {
  @include button-review-light(
    vars.$color-light_grey_20,
    vars.$color-dark_grey_90
  );
}
.btn-light_grey_25 {
  @include button-review-light(
    vars.$color-light_grey_25,
    vars.$color-dark_grey_90
  );
}
.btn-light_grey_30 {
  @include button-review-light(
    vars.$color-light_grey_30,
    vars.$color-dark_grey_90
  );
}
.btn-black {
  @include button-review(vars.$color-black, vars.$color-light);
}
.btn-dark_grey_90 {
  @include button-review(vars.$color-dark_grey_90, vars.$color-light);
}
.btn-dark_grey_80 {
  @include button-review(vars.$color-dark_grey_80, vars.$color-light);
}
.btn-dark_grey_70 {
  @include button-review(vars.$color-dark_grey_70, vars.$color-light);
}
.btn-dark_grey_60 {
  @include button-review(vars.$color-dark_grey_60, vars.$color-light);
}
.btn-dark_grey_50 {
  @include button-review(vars.$color-dark_grey_50, vars.$color-light);
}
.btn-dark_grey_40 {
  @include button-review(vars.$color-dark_grey_40, vars.$color-light);
}
.btn-dark_blue {
  @include button-review(vars.$color-dark_blue, vars.$color-light);
}
.btn-dark_azure {
  @include button-review(vars.$color-dark_azure, vars.$color-light);
}
.btn-dark_red {
  @include button-review(vars.$color-dark_red, vars.$color-light);
}
.btn-dark_yellow {
  @include button-review(vars.$color-dark_yellow, vars.$color-light);
}
.btn-dark_green {
  @include button-review(vars.$color-dark_green, vars.$color-light);
}
.btn-dark_cyan {
  @include button-review(vars.$color-dark_cyan, vars.$color-light);
}
.btn-light_blue {
  @include button-review(vars.$color-light_blue, vars.$color-light);
}
.btn-light_azure {
  @include button-review(vars.$color-light_azure, vars.$color-light);
}
.btn-light_red {
  @include button-review(vars.$color-light_red, vars.$color-light);
}
.btn-light_yellow {
  @include button-review(vars.$color-light_yellow, vars.$color-light);
}
.btn-light_green {
  @include button-review(vars.$color-light_green, vars.$color-light);
}
.btn-light_cyan {
  @include button-review(vars.$color-light_cyan, vars.$color-light);
}
.btn-dark_blue_1 {
  @include button-review(vars.$color-dark_blue_1, vars.$color-light);
}
.btn-dark_blue_2 {
  @include button-review(vars.$color-dark_blue_2, vars.$color-light);
}
.btn-dark_blue_3 {
  @include button-review(vars.$color-dark_blue_3, vars.$color-light);
}
.btn-dark_blue_4 {
  @include button-review(vars.$color-dark_blue_4, vars.$color-light);
}
.btn-dark_blue_5 {
  @include button-review(vars.$color-dark_blue_5, vars.$color-light);
}
.btn-dark_blue_6 {
  @include button-review(vars.$color-dark_blue_6, vars.$color-light);
}
.btn-dark_blue_7 {
  @include button-review(vars.$color-dark_blue_7, vars.$color-light);
}
.btn-dark_blue_8 {
  @include button-review(vars.$color-dark_blue_8, vars.$color-light);
}
.btn-dark_blue_9 {
  @include button-review(vars.$color-dark_blue_9, vars.$color-light);
}
.btn-dark_blue_10 {
  @include button-review(vars.$color-dark_blue_10, vars.$color-light);
}
.btn-dark_blue_11 {
  @include button-review(vars.$color-dark_blue_11, vars.$color-light);
}
.btn-dark_blue_12 {
  @include button-review(vars.$color-dark_blue_12, vars.$color-light);
}

.btn-social {
  &__twitter {
    @include button-review(vars.$color-twitter, vars.$color-light);
  }
  &__facebook {
    @include button-review(vars.$color-facebook, vars.$color-light);
  }
  &__linkedin {
    @include button-review(vars.$color-linkedin, vars.$color-light);
  }
  &__google {
    @include button-review(vars.$color-google, vars.$color-light);
  }
  &__pinterest {
    @include button-review(vars.$color-pinterest, vars.$color-light);
  }
}
@mixin button-review-tag($background, $foregreound, $border) {
  display: inline-block;
  border: 0;
  background-color: transparent;
  position: relative;
  z-index: 1;
  margin-right: 1rem;

  & span {
    position: relative;
    display: block;

    border-radius: 0.8rem 0 0 0.8rem !important;
    height: 3rem;
    line-height: 2.4rem;
    padding: 0 1rem;
    margin: 0 2.2rem 0 0;
    border-top: 0.3rem solid $border;
    border-left: 0.3rem solid $border;
    border-bottom: 0.3rem solid $border;
    border-right: 0;
    color: $foregreound !important;
    background-color: $background !important;
    font-family: vars.$font-heading;
    font-size: vars.$font-size-xxs;
    font-weight: 700;
    text-decoration: none;

    text-align: left;

    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    z-index: 10;
  }

  &::before {
    $side: 2.6rem;

    display: block;
    content: "";
    height: $side;
    width: $side;
    background-color: $background !important;
    border-top: 0.3rem solid $border;
    border-right: 0.3rem solid $border;
    border-radius: 0.8rem;
    transform: rotate(45deg);
    position: absolute;
    //top: 0.3rem;
    margin-top: 0.2rem; //Replacing top with margin-top fixes safari positioning
    right: -0.5rem;
    z-index: 1;
  }
}

.btn-tag {
  @include button-review-tag(
    vars.$color-review-blue-light,
    vars.$color-light,
    vars.$color-review-blue-light
  );

  &.bg-light {
    @include button-review-tag(
      vars.$color-light,
      vars.$color-review-blue,
      vars.$color-review-blue-light
    );
  }
}

.btn-icon {
  color: vars.$color-dark;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  border-radius: vars.$radius-s;

  &.squared {
    width: 2.6rem;
    height: 2.6rem;

    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    // background-color: vars.$color-light-gray-2;
    // color: vars.$color-review-blue;
    // cursor: none !important;
  }

  &.active {
    color: vars.$color-review-blue;
  }
}
